<template>
  <div>
    <page-header></page-header>

    <section class="about-banner img-auto f0">
      <img src="../assets/images/banner-about.jpg" alt="" />
    </section>

    <section class="w1200px">
      <div class="g-channel flex f-wrap">
        <p :class="{ on: showType === 2 }" @click="showType = 2">关于我们</p>
        <p :class="{ on: showType === 1 }" @click="showType = 1">山丘新闻</p>
      </div>
    </section>

    <section class="w1200px" v-show="showType === 1">
      <div class="news-list">
        <div
          class="news-item flex all-s pointer"
          v-for="(item, index) in productList"
          :key="index"
          @click="go(`/news-detail?id=${item.id}&f=山丘新闻&s=新闻详情`)"
        >
          <p class="news-pic">
            <img :src="item.pic" alt="" />
          </p>
          <div class="flex-1">
            <h3 class="f24">
              <b>{{ item.title }}</b>
            </h3>
            <p class="f14 c-999">
              <span>{{ item.createTime }}</span>
              <span class="pl32">阅读：{{ item.count }}</span>
            </p>
            <p class="f18 txt-elips2l lh-2">{{ item.remark }}</p>
          </div>
        </div>
      </div>
    </section>

    <div class="g-page w1200px txt-c" v-show="showType === 1">
      <el-pagination
        hide-on-single-page
        background
        layout="prev, pager, next,total"
        :total="total"
        @current-change="setPage"
      />
    </div>

    <section class="w1200px" v-show="showType === 2">
      <div class="social-media flex all-c txt-c">
        <div class="flex-1">
          <p class="mb10">
            <img src="../assets/images/wechat-public.jpg" width="200" alt="" />
          </p>
          <h3 class="f22">山丘联康公众号</h3>
        </div>
        <div class="flex-1">
          <p class="mb10">
            <img src="../assets/images/liteapp.jpg" width="200" alt="" />
          </p>
          <h3 class="f22">山丘联康小程序</h3>
        </div>
        <!-- <div class="flex-1">
          <p class="mb10">
            <img src="../assets/images/wechat.jpg" width="200" alt="" />
          </p>
          <h3 class="f22">山丘联康微信客服</h3>
        </div>
        <div class="flex-1">
          <p class="mb10">
            <img src="../assets/images/join_wechat_1.jpg" width="200" alt="" />
          </p>
          <h3 class="f22">线下会员店加盟</h3>
        </div>
        <div class="flex-1">
          <p class="mb10">
            <img src="../assets/images/join_wechat_2.jpg" width="200" alt="" />
          </p>
          <h3 class="f22">线下会员店加盟</h3>
        </div> -->
      </div>
      <div class="map-list flex all-c">
        <div class="map-side f22">
          <p
            :class="{ on: markerIndex === index }"
            v-for="(item, index) in markerArr"
            :key="index"
            @click="changePoint(index)"
          >
            {{ item.title }}
          </p>
        </div>
        <div class="flex-1 map-content">
          <div style="width: 100%; height: 536px" id="dituContent"></div>
        </div>
      </div>
    </section>

    <page-footer></page-footer>
  </div>
</template>
<script>
import PageHeader from '@/components/page-header.vue';
import PageFooter from '@/components/page-footer.vue';
export default {
  name: 'home',
  components: { PageHeader, PageFooter },
  data() {
    return {
      showType: 2,
      productList: [],
      total: 0,
      pageIndex: 1,
      markerIndex: 0,
      markerArr: [
        {
          title: '上海健康管理基地',
          content: '上海市闵行区繁兴路500弄1号',
          point: '121.298244|31.21912',
          isOpen: 0,
          icon: { w: 25, h: 25, l: 0, t: 0, x: 6, lb: 15 },
        },
        {
          title: '深圳健康管理中心',
          content: '深圳市福田区园岭街道华林社区八卦四路52号安吉尔大厦1416',
          point: '114.105756|22.570106',
          isOpen: 0,
          icon: { w: 25, h: 25, l: 0, t: 0, x: 6, lb: 15 },
        },
        {
          title: '常州健康管理中心',
          content: '江苏省常州市武进区武进西太湖科技产业园6号楼E座5123室',
          point: '119.863114|31.715029',
          isOpen: 0,
          icon: { w: 25, h: 25, l: 0, t: 0, x: 6, lb: 15 },
        },
        // {
        //   title: '武汉健康管理中心',
        //   content: '武汉市硚口区新世界酒店写字楼8楼',
        //   point: '114.271696|30.584888',
        //   isOpen: 0,
        //   icon: { w: 25, h: 25, l: 0, t: 0, x: 6, lb: 15 },
        // },
        // {
        //   title: '济南健康管理中心',
        //   content: '山东省济南市市中区马鞍山路银座购物广场西四口B1A区-1号',
        //   point: '117.008281|36.654534',
        //   isOpen: 0,
        //   icon: { w: 25, h: 25, l: 0, t: 0, x: 6, lb: 15 },
        // },
        // {
        //   title: '南昌健康管理中心',
        //   content: '江西省南昌市东湖区胜利路时代广场中段611室',
        //   point: '115.89386|28.684161',
        //   isOpen: 0,
        //   icon: { w: 25, h: 25, l: 0, t: 0, x: 6, lb: 15 },
        // },
        // {
        //   title: '嘉兴健康管理中心',
        //   content: '嘉兴常春藤医院（第一医院北门）嘉兴市南湖区清华路252号',
        //   point: '120.763759|30.736243',
        //   isOpen: 0,
        //   icon: { w: 25, h: 25, l: 0, t: 0, x: 6, lb: 15 },
        // },
      ],
    };
  },
  mounted() {
    this.initMap();
    this.init();
  },
  methods: {
    async init() {
      try {
        await this.getProductList();
      } catch (e) {
        this.$error(e.message);
      }
    },
    async setPage(page) {
      try {
        this.pageIndex = page;
        await this.getProductList();
      } catch (e) {
        this.$error(e.message);
      }
    },
    async getProductList() {
      let result = await this.$store.dispatch('getData', {
        url: '/api/n/advlList',
        data: {
          position: 4,
          pageNum: this.pageIndex,
          pageSize: 10,
        },
      });
      result.rows.forEach((item) => {
        item.remark = item.content.replace(/<[^>]+>/g, '').substr(0, 200);
      });
      this.productList = result.rows;
      this.total = result.total;
    },
    //创建和初始化地图函数：
    initMap() {
      this.createMap(); //创建地图
      this.addMapControl(); //向地图添加控件
      this.addMarker(); //向地图中添加marker
    },
    //创建地图函数：
    createMap() {
      var map = new BMap.Map('dituContent'); //在百度地图容器中创建一个地图
      var point = new BMap.Point(121.298244, 31.21912); //定义一个中心点坐标
      map.centerAndZoom(point, 17); //设定地图的中心点和坐标并将地图显示在地图容器中
      window.map = map; //将map变量存储在全局
    },
    //地图控件添加函数：
    addMapControl() {
      //向地图中添加缩放控件
      var ctrl_nav = new BMap.NavigationControl({
        anchor: BMAP_ANCHOR_TOP_LEFT,
        type: BMAP_NAVIGATION_CONTROL_LARGE,
      });
      map.addControl(ctrl_nav);
    },
    //创建marker
    addMarker() {
      for (var i = 0; i < this.markerArr.length; i++) {
        var json = this.markerArr[i];
        var p0 = json.point.split('|')[0];
        var p1 = json.point.split('|')[1];
        var point = new BMap.Point(p0, p1);
        var iconImg = this.createIcon(json.icon);
        var marker = new BMap.Marker(point, { icon: iconImg });
        var label = new BMap.Label(json.title, {
          offset: new BMap.Size(json.icon.lb - json.icon.x + 10, -20),
        });
        marker.setLabel(label);
        map.addOverlay(marker);
        label.setStyle({
          borderColor: '#808080',
          color: '#333',
          cursor: 'pointer',
        });
        label.addEventListener('click', (e) => {
          console.log(e.currentTarget);
          var current = e.currentTarget;
          var iw = this.createInfoWindow(current.content);
          window.map.openInfoWindow(iw, current.point);
        });
      }
    },
    //创建InfoWindow
    createInfoWindow(content) {
      var json = this.markerArr.find((v) => v.title === content);
      var iw = new BMap.InfoWindow(
        "<b class='iw_poi_title' title='" +
        json.title +
        "'>" +
        json.title +
        "</b><div class='iw_poi_content'>" +
        json.content +
        '</div>'
      );
      return iw;
    },
    //创建一个Icon
    createIcon(json) {
      var icon = new BMap.Icon(
        'https://webmap0.bdimg.com/image/api/marker_red.png',
        new BMap.Size(json.w, json.h),
        {
          imageOffset: new BMap.Size(-json.l, -json.t),
          infoWindowOffset: new BMap.Size(json.lb + 5, 1),
          offset: new BMap.Size(json.x, json.h),
        }
      );
      return icon;
    },
    // 切换中心点
    changePoint(index) {
      this.markerIndex = index;
      var json = this.markerArr[index];
      var p0 = json.point.split('|')[0];
      var p1 = json.point.split('|')[1];
      var point = new BMap.Point(p0, p1);
      map.centerAndZoom(point, 17); //设定地图的中心点和坐标并将地图显示在地图容器中
    },
  },
};
</script>
<style>
.iw_poi_title {
  color: #cc5522;
  font-size: 14px;
  font-weight: bold;
  overflow: hidden;
  padding-right: 13px;
  white-space: nowrap;
}
.iw_poi_content {
  font: 12px arial, sans-serif;
  overflow: visible;
  padding-top: 4px;
  white-space: -moz-pre-wrap;
  word-wrap: break-word;
}
</style>
