<template>
  <div>
    <page-header></page-header>

    <div class="g-body">
    <section class="experts-banner img-auto f0"><img src="../assets/images/banner-experts.jpg" alt=""></section>

    <section class="w1100px teams">
      <h2 class="txt-c c-green f36 pb46"><b>全球多学科专家团队</b></h2>
      <p class="lh-16 pb68">
        山丘联康的专家团队由美国杜克大学生活方式医学中心主任艾瑞克西曼教授,
        2017年首届国之名医、上海市医学会糖尿病学会前任主委邹大进教授为首的全国代谢性疾病专家团队领衔，联合中国各大知名三甲医院内分泌及其他领域医学专家、营养学专家、运动医学专家、心理学专家,运用国内外先进技术及数十年临床经验，为会员提供专业的健康咨询及管理服务。
      </p>

      <div class="flex all-c txt-c lh-16 pb60">
        <div class="flex-1 flex-inline txt-l all-c">
          <p class="mr16">
            <img src="../assets/images/ex1.png" width="100" alt="" />
          </p>
          <div class="">
            <h3 class="f18 c-golden"><b>艾瑞克·西曼 / Eric Westman</b></h3>
            <div class="f16 c-666">
              <p>山丘联康 创始人</p>
              <p>山丘联康 首席科学家</p>
              <p>美国杜克大学终身教授</p>
              <p>杜克大学生活方式医学研究中心主任</p>
            </div>
          </div>
        </div>

        <div class="flex-1 flex-inline txt-l all-c">
          <p class="mr16">
            <img src="../assets/images/ex2.png" width="100" alt="" />
          </p>
          <div class="">
            <h3 class="f18 c-golden"><b>邹大进</b></h3>
            <div class="f16 c-666">
              <p>山丘联康 首席医学官</p>
              <p>2017年首届国之名医</p>
              <p>上海市医学会糖尿病学会前任主任委员</p>
              <p>京东糖尿病康复中心学术带头人</p>
            </div>
          </div>
        </div>
      </div>

      <div class="flex all-c lh-16 pb82">
        <div class="flex-1 flex all-c">
          <p class="mr16">
            <img src="../assets/images/ex3.png" width="82" alt="" />
          </p>
          <div class="w210px">
            <h3 class="f16 c-golden"><b>沈远东</b></h3>
            <div class="f14 c-666">
              <p>曙光医院糖尿病中心主任</p>
              <p>国际标准化组织中医药技术委员会</p>
              <p>（ISO/TC249）主席</p>
            </div>
          </div>
        </div>

        <div class="flex-1 flex all-c">
          <p class="mr16">
            <img src="../assets/images/ex4.png" width="82" alt="" />
          </p>
          <div class="w210px">
            <h3 class="f16 c-golden"><b>胡仁明</b></h3>
            <div class="f14 c-666">
              <p>上海华山医院内分泌科主任医师/教授</p>
              <p>复旦大学内分泌糖尿病研究所所长</p>
            </div>
          </div>
        </div>

        <div class="flex-1 flex all-c all-je">
          <p class="mr16">
            <img src="../assets/images/ex5.png" width="82" alt="" />
          </p>
          <div class="w210px">
            <h3 class="f16 c-golden"><b>宁锂</b></h3>
            <div class="f14 c-666">
              <p>杭州第二人民医院</p>
              <p>内分泌科主任医师/教授</p>
            </div>
          </div>
        </div>
      </div>

      <div class="flex all-c lh-16 pb82">
        <div class="flex-1 flex all-c">
          <p class="mr16">
            <img src="../assets/images/ex6.png" width="82" alt="" />
          </p>
          <div class="w210px">
            <h3 class="f16 c-golden"><b>周丽诺</b></h3>
            <div class="f14 c-666">
              <p>上海华山医院</p>
              <p>内分泌科主任医师/教授</p>
            </div>
          </div>
        </div>

        <div class="flex-1 flex all-c">
          <p class="mr16">
            <img src="../assets/images/ex7.png" width="82" alt="" />
          </p>
          <div class="w210px">
            <h3 class="f16 c-golden"><b>杨宏杰</b></h3>
            <div class="f14 c-666">
              <p>上海岳阳中西医结合医院</p>
              <p>内分泌科主任</p>
            </div>
          </div>
        </div>

        <div class="flex-1 flex all-c all-je">
          <p class="mr16">
            <img src="../assets/images/ex8.png" width="82" alt="" />
          </p>
          <div class="w210px">
            <h3 class="f16 c-golden"><b>葛军</b></h3>
            <div class="f14 c-666">
              <p>上海市东医院</p>
              <p>内分泌代谢科主任医师</p>
            </div>
          </div>
        </div>
      </div>

      <div class="flex all-c lh-16 pb82">
        <div class="flex-1 flex all-c">
          <p class="mr16">
            <img src="../assets/images/ex9.png" width="82" alt="" />
          </p>
          <div class="w210px">
            <h3 class="f16 c-golden"><b>焦东海</b></h3>
            <div class="f14 c-666">
              <p>主任医师/博士生导师</p>
              <p>中西医结合内科主任医师</p>
              <p>教授、硕士、博士研究生导师</p>
            </div>
          </div>
        </div>

        <div class="flex-1 flex all-c">
          <p class="mr16">
            <img src="../assets/images/ex10.png" width="82" alt="" />
          </p>
          <div class="w210px">
            <h3 class="f16 c-golden"><b>张金金</b></h3>
            <div class="f14 c-666">
              <p>美国明尼苏达大学博士</p>
              <p>全球知名医学影像研究中心专家</p>
            </div>
          </div>
        </div>

        <div class="flex-1 flex all-c all-je">
          <p class="mr16">
            <img src="../assets/images/ex11.png" width="82" alt="" />
          </p>
          <div class="w210px">
            <h3 class="f16 c-golden"><b>陆大江</b></h3>
            <div class="f14 c-666">
              <p>上海体育学院</p>
              <p>运动科学学院教授</p>
            </div>
          </div>
        </div>
      </div>

      <div class="flex all-c lh-16 pb82">
        <div class="flex-1 flex all-c">
          <p class="mr16">
            <img src="../assets/images/ex12.png" width="82" alt="" />
          </p>
          <div class="w210px">
            <h3 class="f16 c-golden"><b>沈岳良</b></h3>
            <div class="f14 c-666">
              <p>浙江大学医学院</p>
              <p>病理生理学教授</p>
            </div>
          </div>
        </div>

        <div class="flex-1 flex all-c">
          <p class="mr16">
            <img src="../assets/images/ex13.png" width="82" alt="" />
          </p>
          <div class="w210px">
            <h3 class="f16 c-golden"><b>郭红辉</b></h3>
            <div class="f14 c-666">
              <p>广东省营养学会常务理事</p>
              <p>广东医科大学</p>
              <p>公共卫生学院教授</p>
            </div>
          </div>
        </div>

        <div class="flex-1 flex all-c all-je">
          <p class="mr16">
            <img src="../assets/images/ex14.png" width="82" alt="" />
          </p>
          <div class="w210px">
            <h3 class="f16 c-golden"><b>于军</b></h3>
            <div class="f14 c-666">
              <p>心理咨询师</p>
              <p>精神分析家</p>
              <p>人格心理学家</p>
            </div>
          </div>
        </div>
      </div>
    </section>
    </div>

    <page-footer></page-footer>
  </div>
</template>
<script>
import PageHeader from '@/components/page-header.vue';
import PageFooter from '@/components/page-footer.vue';
export default {
  name: 'experts',
  components: { PageHeader, PageFooter },
  data() {
    return {};
  },
  mounted() {},
  methods: {},
};
</script>
