<template>
    <div v-html="html"></div>
</template>
<script>
export default {
    props: {
        html: {
            type: String,
            default: ""
        }
    }
}
</script>

<style lang="scss" scoped>

</style>>
</style>