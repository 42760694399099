<template>
  <div>
    <page-header></page-header>

    <div class="g-body">
      <section class="w1200px g-bread f16">
        <span class="icon icon-xuanzhongshangcheng c-999"></span>
        <span> 位置：</span>
        <span class="c-green2 pointer" @click="go('/')">首页</span>
        <span> > </span>
        <span class="c-green2 pointer" @click="goBack">{{ first }}</span>
        <span> > </span>
        <span>{{ second }}</span>
      </section>

      <section class="w1200px news-content2">
        <h1 class="f36 txt-c">
          <b>{{ product.title }}</b>
        </h1>
        <p class="f16 c-999">
          <!-- <span>2021-07-22</span> -->
          <span>{{ product.createTime }}</span>
          <span class="pl32">阅读：{{ product.count }}</span>
        </p>
        <!-- <div class="html-content" v-html="product.content"></div> -->
        <page-html :html="product.content"></page-html>
      </section>
    </div>

    <page-footer></page-footer>
  </div>
</template>
<script>
import PageHeader from '@/components/page-header.vue';
import PageFooter from '@/components/page-footer.vue';
import { dateFormat } from '@/libs/util';
import PageHtml from '@/components/page-html.vue';
export default {
  name: 'news-detail',
  components: { PageHeader, PageFooter, PageHtml },
  filter: {
    formatPublish(value) {
      return dateFormat(new Date(value))
    }
  },
  data() {
    return {
      product: {},
    };
  },
  computed: {
    first() {
      return this.$route.query.f || '健康频道';
    },
    second() {
      return this.$route.query.s || '健康资讯';
    },
  },
  mounted() {
    this.getProduct();
  },
  methods: {
    async getProduct() {
      try {
        let result = await this.$store.dispatch('getData', {
          url: '/api/n/advInfo',
          data: { id: this.$route.query.id },
        });
        this.product = result.data;
      } catch (e) {
        this.$error(e.message);
      }
    },
  },
};
</script>
<style scoped>

</style>