<template>
  <div>
    <page-header></page-header>

    <div class="g-body">
    <section class="w1200px">
      <div class="g-channel flex f-wrap">
        <p
          :class="{ on: item.id === labelId }"
          v-for="(item, index) in labelList"
          :key="index"
          @click="setLabel(item.id)"
        >
          {{ item.label }}
        </p>
      </div>
      <div class="news-list">
        <div
          class="news-item flex all-s pointer"
          v-for="(item, index) in productList"
          :key="index"
          @click="go(`/news-detail?id=${item.id}`)"
        >
          <p class="news-pic">
            <img :src="item.pic" alt="" />
          </p>
          <div class="flex-1">
            <h3 class="f24">
              <b>{{ item.title }}</b>
            </h3>
            <p class="f14 c-999">
              <span>{{ item.createTime }}</span>
              <span class="pl32">阅读：{{ item.count }}</span>
            </p>
            <p class="f18 txt-elips2l lh-2">{{ item.remark }}</p>
          </div>
        </div>
      </div>
    </section>

    <div class="g-page w1200px txt-c">
      <el-pagination
        hide-on-single-page
        background
        layout="prev, pager, next,total"
        :total="total"
        @current-change="setPage"
      />
    </div>

    </div>

    <page-footer></page-footer>
  </div>
</template>
<script>
import PageHeader from '@/components/page-header.vue';
import PageFooter from '@/components/page-footer.vue';
export default {
  name: 'news',
  components: { PageHeader, PageFooter },
  data() {
    return {
      productList: [],
      labelList: [],
      labelId: 0,
      total: 0,
      pageIndex: 1,
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    async init() {
      try {
        await this.getLabelList();
        if (this.labelList.length) {
          this.labelId = this.labelList[0].id;
        }
        await this.getProductList();
      } catch (e) {
        this.$error(e.message);
      }
    },
    async setPage(page) {
      try {
        this.pageIndex = page;
        await this.getProductList();
      } catch (e) {
        this.$error(e.message);
      }
    },
    async setLabel(labelId) {
      try {
        this.labelId = labelId;
        this.pageIndex = 1;
        await this.getProductList();
      } catch (e) {
        this.$error(e.message);
      }
    },
    async getProductList() {
      let result = await this.$store.dispatch('getData', {
        url: '/api/n/advlList',
        data: {
          position: 3,
          labelId: this.labelId,
          pageNum: this.pageIndex,
          pageSize: 10,
        },
      });
      result.rows.forEach((item) => {
        item.remark = item.content.replace(/<[^>]+>/g, '').substr(0, 200);
      });
      this.productList = result.rows;
      this.total = result.total;
    },
    async getLabelList() {
      let result = await this.$store.dispatch('getData', {
        url: '/api/n/labelList',
        data: { type: 10, labelId: this.labelId },
      });
      this.labelList = result.rows;
    },
  },
};
</script>
