export function transArrayToObject(ary, key, valueKey) {
  let obj = {};
  for (let item of ary) {
    if (!valueKey) {
      obj[item[key]] = JSON.parse(JSON.stringify(item));
    } else {
      obj[item[key]] = item[valueKey];
    }
  }
  return obj;
}

export function sleep(time) {
  return new Promise(resolve => setTimeout(resolve, time));
}

export function dateFormat(date, fmt = 'yyyy-MM-dd') {
  if (typeof date === 'string') return date;
  let o = {
    'M+': date.getMonth() + 1, // 月份
    'd+': date.getDate(), // 日
    'h+': date.getHours(), // 小时
    'm+': date.getMinutes(), // 分
    's+': date.getSeconds(), // 秒
    'q+': Math.floor((date.getMonth() + 3) / 3), // 季度
    S: date.getMilliseconds() // 毫秒
  };
  if (/(y+)/.test(fmt)) {
    fmt = fmt.replace(RegExp.$1, (date.getFullYear() + '').substr(4 - RegExp.$1.length));
  }
  for (let k in o) {
    if (new RegExp('(' + k + ')').test(fmt)) {
      fmt = fmt.replace(RegExp.$1, RegExp.$1.length === 1 ? o[k] : ('00' + o[k]).substr(('' + o[k]).length));
    }
  }
  return fmt;
}


export function dateFromString(dateString) {
  if (dateString) {
    let arr1 = dateString.split(' ');
    let sdate = arr1[0].split('-');
    let sSecond = arr1[1].split(':');
    let date = new Date(sdate[0], sdate[1] - 1, sdate[2], sSecond[0], sSecond[1], sSecond[2]);
    return date;
  }
}

const cubic = value => Math.pow(value, 3);
const easeInOutCubic = value => value < 0.5 ? cubic(value * 2) / 2 : 1 - cubic((1 - value) * 2) / 2;

export function scrollToBottom(el) {
  const beginTime = Date.now();
  const endValue = el.scrollHeight;
  const rAF = window.requestAnimationFrame || (func => setTimeout(func, 16));
  const frameFunc = () => {
    const progress = (Date.now() - beginTime) / 500;
    if (progress < 1) {
      el.scrollTop = endValue * (1 + easeInOutCubic(progress));
      rAF(frameFunc);
    } else {
      el.scrollTop = endValue;
    }
  };
  rAF(frameFunc);
}


